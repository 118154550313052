import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { hasSlugs, PAGE_OPTIONS } from '../../lib/utils/page';
import { PageTemplate } from '../../page-templates/generic-page';
const {
  ERROR_500,
  ERROR_404,
  ERROR_504,
  REFERRAL_INVALID
} = PAGE_OPTIONS;
export const ErrorComponent: FunctionComponent<Props> = ({
  statusCode,
  asPath
}) => {
  // DO NOT use useRouter() here, it causes a Next.js error
  const config = useMemo(() => {
    switch (statusCode) {
      case 404:
        if (hasSlugs(asPath, ['o/', 'r/'])) return REFERRAL_INVALID;
        return ERROR_404;
      case 504:
        return ERROR_504;
      default:
        return ERROR_500;
    }
  }, [statusCode]);
  return <PageTemplate {...config} data-sentry-element="PageTemplate" data-sentry-component="ErrorComponent" data-sentry-source-file="index.tsx" />;
};
type Props = {
  statusCode?: number;
  asPath: string;
};